<template>
  <div>
    <v-menu left rounded>
      <template v-slot:activator="{ on, attrs }">
        <button class="btn btn-sm btn-icon" v-bind="attrs" v-on="on">
          <span class="svg-icon">
            <v-icon size="19"> mdi-dots-vertical </v-icon>
          </span>
        </button>
      </template>

      <v-list class="py-0">
        <v-list-item
          :disabled="!item.is_selected"
          v-if="editType === 'action'"
          class="text-center"
        >
          <v-list-item-title
            class="poppins d-flex align-items-center font-size-h6"
          >
            <div
              class="form-check form-check-sm form-check-custom form-check-solid me-2 min-w-20px min-h-20px"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="item.only_related"
                @click="
                  (event) =>
                    onlyRelatedToggleSelect(event.target.checked, item.name)
                "
              />
            </div>
            Only related
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="editType === 'role'"
          class="text-center bg-hover-light-info"
          @click.prevent="() => $emit('handleEditRole', item)"
        >
          <v-list-item-title class="poppins font-size-h6"
            >Edit</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          v-if="editType === 'role'"
          class="text-center bg-hover-light-info"
          @click.prevent="() => $emit('handleDuplicateRole', item)"
        >
          <v-list-item-title class="poppins font-size-h6"
            >Duplicate</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          v-if="editType === 'role'"
          class="text-center bg-hover-light-info"
          @click.prevent="() => removeItem(item.name)"
        >
          <v-list-item-title class="poppins font-size-h6"
            >Delete</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import swalDeleteQuery from "@/core/services/AlertServices/SwalDeleteQuery";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import {
  ONLY_RELATED_TOGGLE,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/roles.module";

export default {
  name: "ActionsMenu",
  props: ["item", "editType"],
  methods: {
    onlyRelatedToggleSelect(isChecked, action_id) {
      this.$store.commit(ONLY_RELATED_TOGGLE, {
        action_id,
        isChecked,
      });
    },
    removeItem(id) {
      swalDeleteQuery().then((result) => {
        if (result.isConfirmed) {
          this.$store.commit(SET_PAGE_LOADING, true);
          ApiService.post("/authorizations/roles/destroy", {
            id,
          })
            .then(() => {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
            })
            .finally(() => {
              this.$store.dispatch(UPDATE_TABLE_STRUCTURE).finally(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              });
            });
        }
      });
    },
  },
};
</script>
