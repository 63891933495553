<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          {{
            isForDuplicate
              ? "Duplicate Role"
              : Boolean(item)
              ? "Edit Role"
              : "Add New Role"
          }}
        </h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-300px scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0">
          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.title"
              label="Role"
              clearable
              outlined
              dense
              :error-messages="titleErrors"
              @input="$v.formData.title.$touch()"
              @blur="$v.formData.title.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              dense
            />
          </div>
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { UPDATE_TABLE_STRUCTURE } from "@/core/services/store/roles.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "CreateRole",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      title: { required },
    },
  },
  data: () => ({
    dialog: false,
    formData: {
      title: null,
      description: null,
    },
    item: null,
    isForDuplicate: false,
  }),
  methods: {
    toggleModal(item = null, isForDuplicate = false) {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.isForDuplicate = isForDuplicate;

        this.item = item;
        this.formData.title = item?.title;
        this.formData.description = item?.description;
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v?.formData[fieldName]?.$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    async submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit(SET_PAGE_LOADING, true);

      if (this.item) {
        if (this.isForDuplicate) {
          ApiService.post(`/authorizations/roles/duplicate`, {
            role_id: this.item.name,
            name: this.formData.title,
            description: this.formData.description,
          })
            .then(() => {
              swalAdded();
              this.toggleModal();
            })
            .finally(() => {
              this.$store.dispatch(UPDATE_TABLE_STRUCTURE).finally(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              });
            });
        } else {
          const localRole = this.$store.getters.getROLERoles.find(
            (role) => role.name === this.item.name
          );

          await ApiService.post(`/authorizations/roles/update`, {
            ...localRole,
            role_id: this.item.name,
            name: this.formData.title,
            description: this.formData.description,
          })
            .then(() => {
              swalAdded();
              this.toggleModal();
            })
            .finally(() => {
              this.$store.dispatch(UPDATE_TABLE_STRUCTURE).finally(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              });
            });
        }
      } else {
        await ApiService.post(`/authorizations/roles/store`, {
          ...this.formData,
          entities: [],
        })
          .then(() => {
            swalAdded();
            this.toggleModal();
          })
          .finally(() => {
            this.$store.dispatch(UPDATE_TABLE_STRUCTURE).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          });
      }
    },
    resetFormData() {
      this.formData = {
        title: null,
        description: null,
      };
    },
  },
  computed: {
    titleErrors: function() {
      return this.handleFormValidation("title");
    },
  },
};
</script>
